import isValid from 'date-fns/isValid';
import formatDate from 'date-fns/format';
import formatISO from 'date-fns/formatISO';
import parseISO from 'date-fns/parseISO';
import isEqual from 'date-fns/isEqual';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import setMilliseconds from 'date-fns/setMilliseconds';
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';
import isBefore from 'date-fns/isBefore';
import setSeconds from 'date-fns/setSeconds';
import addMinutes from 'date-fns/addMinutes';
import startOfISOWeek from 'date-fns/startOfISOWeek';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import subMonths from 'date-fns/subMonths';
import getISOWeek from 'date-fns/getISOWeek';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import startOfYear from 'date-fns/startOfYear';
import endOfYear from 'date-fns/endOfYear';
import subWeeks from 'date-fns/subWeeks';
import endOfISOWeek from 'date-fns/endOfISOWeek';
import { addWeeks } from 'date-fns';

class DateFormats {
  static FORMATS = Object.freeze({
    SHORT_MONTH_AND_DAY: 'MMM. d',
    DAY_AND_SHORT_MONTH: 'd MMM.',
    HOURS_AND_MINUTES: 'hh:mm aa',
    HOURS_AND_MINUTES_24: 'HH:mm',
    HOURS: 'h aa',
    HOURS_24: 'HH',
    MINUTES: 'mm',
    YEAR: 'yyyy',
    MONTH: 'MMMM',
    DAY: 'dd',
    STANDARD_DATE: 'MM/dd/yyyy',
    DAY_MONTH_YEAR: 'dd / MM / yyyy',
    DAY_MONTH_SHORT_YEAR: 'dd/MM/yy',
    DATEPICKER: 'MMMM / dd / yyyy',
    SHORT_MONTH_AND_YEAR: 'MMM. yyyy',
    SHORT_MONTH_AND_DAY_AND_YEAR: 'MMM. dd • yyyy',
    SHORT_DATE_WITH_TIME_24: 'MMM. dd • yyyy HH:mm',
    SHORT_WEEKDAY: 'ccc.',
    FULL_WEEKDAY: 'EEEE',
    EQUIPMENT_PROCEDURE: 'dd/MM/yy',
    WEEK_NUMBER: 'I',
    API_DATE: 'yyyy-MM-dd',
  });

  static isDateValid = (date) => {
    return Boolean(date && isValid(new Date(date)));
  };

  static areDatesEqual = (date1, date2) => {
    const isEveryDateValid = [date1, date2].every(this.isDateValid);

    if (!isEveryDateValid) {
      throw new Error('Dates are invalid!');
    }

    return isEqual(date1, date2);
  };

  static validateAndFormat(date, format, fallback = '--') {
    if (this.isDateValid(date)) {
      return formatDate(new Date(date), format);
    }
    return fallback;
  }

  static validateAndFormatISO(date, format, fallback = '') {
    if (this.isDateValid(date)) {
      return formatISO(new Date(date), format);
    }
    return fallback;
  }

  static validateAndFormatISOUTC(date, format, fallback = '') {
    if (this.isDateValid(date)) {
      const dateObj = new Date(date);

      return formatISO(
        addMinutes(dateObj, dateObj.getTimezoneOffset()),
        format
      );
    }
    return fallback;
  }

  static validateAndGetStartOfISOWeek(date, options = {}, fallback = '--') {
    if (this.isDateValid(date)) {
      return startOfISOWeek(date, options);
    }
    return fallback;
  }

  static validateAndGetEndOfISOWeek(date, options = {}, fallback = '--') {
    if (this.isDateValid(date)) {
      return endOfISOWeek(date, options);
    }
    return fallback;
  }

  static validateAndParseISO(date, options = {}, fallback = '--') {
    if (this.isDateValid(parseISO(date))) {
      return parseISO(date, options);
    }
    return fallback;
  }

  static shortMonthAndDay(date, fallback) {
    return this.validateAndFormat(
      date,
      this.FORMATS.SHORT_MONTH_AND_DAY,
      fallback
    );
  }

  static dayAndShortMonth(date, fallback) {
    return this.validateAndFormat(
      date,
      this.FORMATS.DAY_AND_SHORT_MONTH,
      fallback
    );
  }

  static hoursAndMinutes(date, fallback) {
    return this.validateAndFormat(
      date,
      this.FORMATS.HOURS_AND_MINUTES,
      fallback
    );
  }

  static hoursAndMinutes24(date, fallback) {
    return this.validateAndFormat(
      date,
      this.FORMATS.HOURS_AND_MINUTES_24,
      fallback
    );
  }

  static hours(date, fallback) {
    return this.validateAndFormat(date, this.FORMATS.HOURS, fallback);
  }

  static hours24(date, fallback) {
    return this.validateAndFormat(date, this.FORMATS.HOURS_24, fallback);
  }

  static minutes(date, fallback) {
    return this.validateAndFormat(date, this.FORMATS.MINUTES, fallback);
  }

  static year(date, fallback) {
    return this.validateAndFormat(date, this.FORMATS.YEAR, fallback);
  }

  static month(date, fallback) {
    return this.validateAndFormat(date, this.FORMATS.MONTH, fallback);
  }

  static day(date, fallback) {
    return this.validateAndFormat(date, this.FORMATS.DAY, fallback);
  }

  static standardDate(date, fallback) {
    return this.validateAndFormat(date, this.FORMATS.STANDARD_DATE, fallback);
  }

  static dayMonthShortYear(date, fallback) {
    return this.validateAndFormat(
      date,
      this.FORMATS.DAY_MONTH_SHORT_YEAR,
      fallback
    );
  }

  static dayMonthYear(date, fallback) {
    return this.validateAndFormat(date, this.FORMATS.DAY_MONTH_YEAR, fallback);
  }

  static datepicker(date, fallback) {
    return this.validateAndFormat(
      date,
      this.FORMATS.DATEPICKER,
      fallback || this.FORMATS.DATEPICKER
    );
  }

  static shortMonthAndYear(date, fallback) {
    return this.validateAndFormat(
      date,
      this.FORMATS.SHORT_MONTH_AND_YEAR,
      fallback
    );
  }

  static shortMonthAndDayAndYear(date, delimiter = ' •', fallback) {
    return this.validateAndFormat(
      date,
      this.FORMATS.SHORT_MONTH_AND_DAY_AND_YEAR.replace(' •', delimiter),
      fallback
    );
  }

  static shortDateWithTime24(date, delimiter = ' •', fallback) {
    return this.validateAndFormat(
      date,
      this.FORMATS.SHORT_DATE_WITH_TIME_24.replace(' •', delimiter),
      fallback
    );
  }

  static shortWeekday(date, fallback) {
    return this.validateAndFormat(date, this.FORMATS.SHORT_WEEKDAY, fallback);
  }

  static fullWeekday(date, fallback) {
    return this.validateAndFormat(date, this.FORMATS.FULL_WEEKDAY, fallback);
  }

  static toISODate(date, fallback) {
    return this.validateAndFormatISO(
      date,
      { representation: 'date' },
      fallback
    );
  }

  static toISOUTCDate(date, fallback) {
    return this.validateAndFormatISOUTC(
      date,
      { representation: 'date' },
      fallback
    );
  }

  static startOfISOWeek(date, fallback) {
    return this.validateAndGetStartOfISOWeek(date, {}, fallback);
  }

  static parseISO(date, fallback) {
    return this.validateAndParseISO(date, {}, fallback);
  }

  static setTime = (
    dateOrigin,
    hours = 0,
    minutes = 0,
    seconds = 0,
    milliseconds = 0
  ) => {
    if (!this.isDateValid(dateOrigin)) {
      throw new Error('Date is invalid');
    }

    let date = new Date(dateOrigin);

    date = setHours(date, hours);
    date = setMinutes(date, minutes);
    date = setSeconds(date, seconds);
    date = setMilliseconds(date, milliseconds);

    return date;
  };

  static areDatesWithoutTimeEqual = (date1Origin, date2Origin) => {
    let date1 = new Date(date1Origin);
    let date2 = new Date(date2Origin);

    date1 = DateFormats.setTime(date1);
    date2 = DateFormats.setTime(date2);

    return DateFormats.areDatesEqual(date1, date2);
  };

  static subDays = (dateOrigin, daysNumber) => {
    if (!this.isDateValid(dateOrigin)) {
      throw new Error('Date is invalid');
    }

    const date = new Date(dateOrigin);

    return subDays(date, daysNumber);
  };

  static subWeeks = (dateOrigin, daysNumber) => {
    if (!this.isDateValid(dateOrigin)) {
      throw new Error('Date is invalid');
    }

    const date = new Date(dateOrigin);

    return subWeeks(date, daysNumber);
  };

  static addDays = (dateOrigin, daysNumber) => {
    if (!this.isDateValid(dateOrigin)) {
      throw new Error('Date is invalid');
    }

    const date = new Date(dateOrigin);

    return addDays(date, daysNumber);
  };

  static weekNumber(date, fallback) {
    return this.validateAndFormat(date, this.FORMATS.WEEK_NUMBER, fallback);
  }

  static isDateBefore(date, dateToCompare) {
    if (!this.isDateValid(date)) {
      throw new Error('date is invalid');
    }

    if (!this.isDateValid(dateToCompare)) {
      throw new Error('dateToCompare is invalid');
    }

    return isBefore(date, dateToCompare);
  }

  static getStartOfMonth(date) {
    if (!this.isDateValid(date)) {
      throw new Error('date is invalid');
    }

    return startOfMonth(date);
  }

  static getEndOfMonth(date) {
    if (!this.isDateValid(date)) {
      throw new Error('date is invalid');
    }

    return endOfMonth(date);
  }

  static getStartOfYear(date, options) {
    if (!this.isDateValid(date)) {
      throw new Error('date is invalid');
    }

    return startOfYear(date, options);
  }

  static getEndOfYear(date, options) {
    if (!this.isDateValid(date)) {
      throw new Error('date is invalid');
    }

    return endOfYear(date, options);
  }

  static getDateMonthBorders(date) {
    if (!this.isDateValid(date)) {
      throw new Error('date is invalid');
    }

    return [this.getStartOfMonth(date), this.getEndOfMonth(date)];
  }

  static getDateWeeksBorders(date) {
    if (!this.isDateValid(date)) {
      throw new Error('date is invalid');
    }

    return [
      this.startOfWeek(date, { weekStartsOn: 1 }),
      this.endOfWeek(date, { weekStartsOn: 1 }),
    ];
  }

  static getDateYearBorders(date) {
    if (!this.isDateValid(date)) {
      throw new Error('date is invalid');
    }

    return [
      this.getStartOfYear(date, { weekStartsOn: 1 }),
      this.getEndOfYear(date, { weekStartsOn: 1 }),
    ];
  }

  static getYearWeeksNumber(dateOrigin, options) {
    if (!this.isDateValid(dateOrigin)) {
      throw new Error('date is invalid');
    }

    return getISOWeek(dateOrigin, options);
  }

  static addMonths(date, monthCount) {
    if (!this.isDateValid(date)) {
      throw new Error('date is invalid');
    }

    if (monthCount < 0) {
      throw new Error('monthCount should be equal or bigger than 0');
    }

    return addMonths(new Date(date), monthCount);
  }

  static addWeeks(date, weekCount) {
    if (!this.isDateValid(date)) {
      throw new Error('date is invalid');
    }

    if (weekCount < 0) {
      throw new Error('weekCount should be equal or bigger than 0');
    }

    return addWeeks(new Date(date), weekCount);
  }

  static subMonths(date, monthCount) {
    if (!this.isDateValid(date)) {
      throw new Error('date is invalid');
    }

    if (monthCount < 0) {
      throw new Error('monthCount should be equal or bigger than 0');
    }

    return subMonths(new Date(date), monthCount);
  }

  static formatToApiDate(date) {
    return this.validateAndFormat(date, this.FORMATS.API_DATE);
  }

  static startOfWeek(date, options) {
    if (!this.isDateValid(date)) {
      throw new Error('Date is invalid');
    }

    return startOfWeek(new Date(date), options);
  }

  static endOfWeek(date, options) {
    if (!this.isDateValid(date)) {
      throw new Error('Date is invalid');
    }

    return endOfWeek(new Date(date), options);
  }

  static getDoesWeekIncludeDay(weekDayOriginal, dayOriginal) {
    if (!this.isDateValid(weekDayOriginal) || !this.isDateValid(dayOriginal)) {
      throw new Error('Date is invalid');
    }

    const day = this.setTime(new Date(dayOriginal));
    const weekDay = this.setTime(new Date(weekDayOriginal));

    const rawStartDate = this.startOfWeek(weekDay, { weekStartsOn: 1 });
    const startDateWithNoTime = this.setTime(rawStartDate);

    const rawEndDate = this.endOfWeek(weekDay, { weekStartsOn: 1 });
    const endDateWithNoTime = this.setTime(rawEndDate);

    return (
      Boolean(
        this.areDatesEqual(day, startDateWithNoTime) ||
          this.areDatesEqual(day, endDateWithNoTime)
      ) ||
      Boolean(
        this.isDateBefore(startDateWithNoTime, day) &&
          this.isDateBefore(day, endDateWithNoTime)
      )
    );
  }
}

export default DateFormats;
