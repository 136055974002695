import { projectLayoutRequest } from 'api/requestWrappers/ProjectLayoutRequest';
import FileModel from 'api/models/FileModel';
import ProjectModel from 'api/models/Project/ProjectModel';
import { required } from 'utils/helpers';
import ProjectPublicDataModel from 'api/models/Project/ProjectPublicDataModel';
import { buildingRequest } from 'api/requestWrappers/Building/BuildingRequest';
import Pager from 'api/helpers/Pager';
import { lastPageSelector, projectsSelector } from './selectors';
import { DEFAULT_UPDATE_META_CONFIG, setPageNumber } from '../helpers';
import {
  ACTIVATE_PROJECT_ACTION,
  ADD_TENANT_LOCATION_ACTION,
  ARCHIVE_PROJECT_ACTION,
  ASSIGN_BENEFICIARY_USER_ACTION,
  ASSIGN_TEAM_MEMBER_USER_ACTION,
  ASSIGN_TENANT_USER_ACTION,
  CREATE_PROJECT_ACTION,
  CREATE_PROJECT_LAYOUT_FILES_ACTION,
  DEASSIGN_BENEFICIARY_USER_ACTION,
  DEASSIGN_TEAM_MEMBER_USER_ACTION,
  DEASSIGN_TENANT_USER_ACTION,
  DELETE_PROJECT_ACTION,
  DELETE_PROJECT_LAYOUT_FILES_ACTION,
  FIND_PROJECT_ACTION,
  GET_ASSIGNED_PROJECTS_ACTION,
  GET_PROJECT_LAYOUT_FILES_ACTION,
  GET_PROJECT_PUBLIC_INF0_ACTION,
  GET_PROJECT_STATISTICS_ACTION,
  GET_PROJECT_USERS_STATISTICS_ACTION,
  GET_PROJECTS_ACTION,
  PROJECT_LIST_TABS_SET_STATE_COUNTS,
  REMOVE_TENANT_LOCATION_ACTION,
  UNARCHIVE_PROJECT_ACTION,
  UPDATE_PROJECT_ACTION,
} from './constants';
import { projectRequest } from '../../api/requestWrappers/ProjectRequest';

export function getProjects(pager, query = {}, resetToDefault = false) {
  return (dispatch) => {
    dispatch(
      GET_PROJECTS_ACTION.REQUEST({
        pager,
        query,
        resetToDefault,
      })
    );
    return projectRequest
      .get(pager, query)
      .then(({ total, resources }) =>
        dispatch(
          GET_PROJECTS_ACTION.SUCCESS({
            resourceModel: ProjectModel,
            resources,
            pager,
            total,
            query,
          })
        )
      )
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        throw dispatch(
          GET_PROJECTS_ACTION.ERROR({
            error: error.message,
            pager,
            query,
          })
        );
      });
  };
}

export function getProjectStatistics(
  status,
  pager = new Pager(),
  query = {},
  resetToDefault = false
) {
  pager && Pager.check(pager);
  required({ status });
  return (dispatch) => {
    dispatch(
      GET_PROJECT_STATISTICS_ACTION.REQUEST({
        pager,
        query,
        resetToDefault,
      })
    );
    return projectRequest
      .getStatistics(status, pager, query)
      .then(({ total, resources }) =>
        dispatch(
          GET_PROJECT_STATISTICS_ACTION.SUCCESS({
            resourceModel: ProjectModel,
            resources,
            pager,
            total,
            query,
          })
        )
      )
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        throw dispatch(
          GET_PROJECT_STATISTICS_ACTION.ERROR({
            error: error.message,
            pager,
            query,
          })
        );
      });
  };
}

export function getProjectPublicData(id, query) {
  if (!id) {
    throw new Error('Id is required to get project public data');
  }

  return (dispatch) => {
    dispatch(
      GET_PROJECT_PUBLIC_INF0_ACTION.REQUEST({
        resourceModel: ProjectPublicDataModel,
        id,
      })
    );
    return projectRequest
      .getPublicData(id, query)
      .then((projectPublicData) => {
        return dispatch(
          GET_PROJECT_PUBLIC_INF0_ACTION.SUCCESS({
            resource: new ProjectPublicDataModel({ ...projectPublicData, id }),
            resourceModel: ProjectPublicDataModel,
          })
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        throw dispatch(
          GET_PROJECT_PUBLIC_INF0_ACTION.ERROR({
            resourceModel: ProjectPublicDataModel,
            id,
            error: error.message,
            statusCode: error.response.status,
          })
        );
      });
  };
}

export function findProject(id, query) {
  if (!id) {
    throw new Error('Id is required to find a project!');
  }
  return (dispatch) => {
    dispatch(FIND_PROJECT_ACTION.REQUEST({ resourceModel: ProjectModel, id }));
    return projectRequest
      .find(id, query)
      .then((projectData) =>
        dispatch(
          FIND_PROJECT_ACTION.SUCCESS({
            resource: new ProjectModel({ ...projectData }),
            resourceModel: ProjectModel,
          })
        )
      )
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        throw dispatch(
          FIND_PROJECT_ACTION.ERROR({
            resourceModel: ProjectModel,
            id,
            error: error.message,
          })
        );
      });
  };
}

export function createProject(
  attributes,
  pager = new Pager(),
  query = {},
  meta = { shouldFetchProjects: true }
) {
  pager && Pager.check(pager);
  return (dispatch) => {
    dispatch(
      CREATE_PROJECT_ACTION.REQUEST({
        attributes,
      })
    );
    return projectRequest
      .create(attributes)
      .then(({ id }) => {
        if (meta.shouldFetchProjects) {
          dispatch(getProjects(pager, query));
        }
        return dispatch(CREATE_PROJECT_ACTION.SUCCESS({ id }));
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        throw dispatch(
          CREATE_PROJECT_ACTION.ERROR({
            attributes,
            error: error.message,
          })
        );
      });
  };
}

export function updateProject(
  id,
  attributes,
  pager = new Pager(),
  query = {},
  meta
) {
  pager && Pager.check(pager);
  // eslint-disable-next-line no-param-reassign
  meta = { ...DEFAULT_UPDATE_META_CONFIG, ...meta };
  if (!id) {
    throw new Error('Id is required to update a project!');
  }
  return (dispatch) => {
    dispatch(
      UPDATE_PROJECT_ACTION.REQUEST({
        resourceModel: ProjectModel,
        id,
        attributes,
      })
    );

    return projectRequest
      .update(id, attributes)
      .then(() => {
        if (meta.shouldFetchResources) {
          dispatch(getProjects(pager, query));
        }

        return dispatch(
          UPDATE_PROJECT_ACTION.SUCCESS({
            id,
            resourceModel: ProjectModel,
            resourceAttributes: attributes,
          })
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        throw dispatch(
          UPDATE_PROJECT_ACTION.ERROR({
            resourceModel: ProjectModel,
            id,
            error: error.message,
          })
        );
      });
  };
}

export function deleteProject(
  id,
  pager = new Pager(),
  query = {},
  meta = { shouldFetchProjects: true }
) {
  pager && pager && Pager.check(pager);
  if (!id) {
    throw new Error('Id is required to delete a project!');
  }
  return (dispatch, getState) => {
    dispatch(
      DELETE_PROJECT_ACTION.REQUEST({
        resourceModel: ProjectModel,
        id,
      })
    );
    return projectRequest
      .delete(id)
      .then(() => {
        const nextPageNumber = pager?.pageNumber
          ? setPageNumber(
              getState(),
              pager?.pageNumber,
              lastPageSelector,
              projectsSelector
            )
          : false;
        const goToPreviousPage = nextPageNumber !== pager?.pageNumber;
        if (meta.shouldFetchProjects && !goToPreviousPage) {
          dispatch(
            getProjects(new Pager(nextPageNumber, pager?.pageSize), query, true)
          );
        }
        return dispatch(
          DELETE_PROJECT_ACTION.SUCCESS({ id, goToPreviousPage })
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        throw dispatch(
          DELETE_PROJECT_ACTION.ERROR({
            resourceModel: ProjectModel,
            id,
            error: error.message,
          })
        );
      });
  };
}

export function activateProject(id) {
  required({ id });
  return (dispatch) => {
    dispatch(
      ACTIVATE_PROJECT_ACTION.REQUEST({
        id,
      })
    );
    return projectRequest
      .activateProject(id)
      .then(() => {
        return dispatch(ACTIVATE_PROJECT_ACTION.SUCCESS({ id }));
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        throw dispatch(
          ACTIVATE_PROJECT_ACTION.ERROR({
            id,
            error: error.message,
          })
        );
      });
  };
}

export function archiveProject(id) {
  required({ id });
  return (dispatch) => {
    dispatch(
      ARCHIVE_PROJECT_ACTION.REQUEST({
        id,
      })
    );
    return projectRequest
      .archiveProject(id)
      .then(() => {
        return dispatch(ARCHIVE_PROJECT_ACTION.SUCCESS({ id }));
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        throw dispatch(
          ARCHIVE_PROJECT_ACTION.ERROR({
            id,
            error: error.message,
          })
        );
      });
  };
}

export function unarchiveProject(id) {
  required({ id });
  return (dispatch) => {
    dispatch(
      UNARCHIVE_PROJECT_ACTION.REQUEST({
        id,
      })
    );
    return projectRequest
      .unarchiveProject(id)
      .then(() => {
        return dispatch(UNARCHIVE_PROJECT_ACTION.SUCCESS({ id }));
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        throw dispatch(
          UNARCHIVE_PROJECT_ACTION.ERROR({
            id,
            error: error.message,
          })
        );
      });
  };
}

export function setProjectListTabsStateCounts({ active, draft, archived }) {
  return {
    type: PROJECT_LIST_TABS_SET_STATE_COUNTS,
    payload: {
      ...(Number.isInteger(active) ? { active } : {}),
      ...(Number.isInteger(draft) ? { draft } : {}),
      ...(Number.isInteger(archived) ? { archived } : {}),
    },
  };
}

export function getProjectUsersStatistics(projectId) {
  required({ projectId });
  return (dispatch) => {
    dispatch(
      GET_PROJECT_USERS_STATISTICS_ACTION.REQUEST({
        projectId,
      })
    );
    return projectRequest
      .getUserStatsByProjectId(projectId)
      .then(
        ({ teamMemberCount, tenantMemberCount, beneficiaryMemberCount }) => {
          return dispatch(
            GET_PROJECT_USERS_STATISTICS_ACTION.SUCCESS({
              projectId,
              usersStatistics: {
                teamMemberCount,
                tenantMemberCount,
                beneficiaryMemberCount,
              },
            })
          );
        }
      )
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        throw dispatch(
          GET_PROJECT_USERS_STATISTICS_ACTION.ERROR({
            projectId,
            error: error.message,
          })
        );
      });
  };
}

export function getAssignedProjects(
  pager = new Pager(),
  query = {},
  resetToDefault = false
) {
  pager && Pager.check(pager);
  return (dispatch) => {
    dispatch(
      GET_ASSIGNED_PROJECTS_ACTION.REQUEST({
        pager,
        query,
        resetToDefault,
      })
    );
    return projectRequest
      .getAssignedProjects(pager, query)
      .then(({ total, resources }) =>
        dispatch(
          GET_ASSIGNED_PROJECTS_ACTION.SUCCESS({
            resourceModel: ProjectModel,
            resources,
            pager,
            total,
            query,
          })
        )
      )
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        throw dispatch(
          GET_ASSIGNED_PROJECTS_ACTION.ERROR({
            error: error.message,
            pager,
            query,
          })
        );
      });
  };
}

export function assignTeamMemberUser(userId, projectId) {
  required({ userId, projectId });
  return (dispatch) => {
    dispatch(
      ASSIGN_TEAM_MEMBER_USER_ACTION.REQUEST({
        userId,
        projectId,
      })
    );
    return projectRequest
      .assignUser(userId, projectId)
      .then(() => {
        return dispatch(
          ASSIGN_TEAM_MEMBER_USER_ACTION.SUCCESS({
            userId,
            projectId,
          })
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        throw dispatch(
          ASSIGN_TEAM_MEMBER_USER_ACTION.ERROR({
            userId,
            projectId,
            error: error.message,
          })
        );
      });
  };
}

export function deassignTeamMember(userId, projectId) {
  required({ userId, projectId });
  return (dispatch) => {
    dispatch(
      DEASSIGN_TEAM_MEMBER_USER_ACTION.REQUEST({
        userId,
        projectId,
      })
    );
    return projectRequest
      .deassignUser(userId, projectId)
      .then(() => {
        return dispatch(
          DEASSIGN_TEAM_MEMBER_USER_ACTION.SUCCESS({
            userId,
            projectId,
          })
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        throw dispatch(
          DEASSIGN_TEAM_MEMBER_USER_ACTION.ERROR({
            userId,
            projectId,
            error: error.message,
          })
        );
      });
  };
}

export function assignTenantUser(userId, projectId, organizationId) {
  required({ userId, projectId, organizationId });
  return (dispatch) => {
    dispatch(
      ASSIGN_TENANT_USER_ACTION.REQUEST({
        userId,
        projectId,
        organizationId,
      })
    );
    return projectRequest
      .assignUser(userId, projectId)
      .then(() => {
        return dispatch(
          ASSIGN_TENANT_USER_ACTION.SUCCESS({
            userId,
            projectId,
            organizationId,
          })
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        throw dispatch(
          ASSIGN_TENANT_USER_ACTION.ERROR({
            userId,
            projectId,
            organizationId,
            error: error.message,
          })
        );
      });
  };
}

export function deassignTenantUser(userId, projectId, organizationId) {
  required({ userId, projectId, organizationId });
  return (dispatch) => {
    dispatch(
      DEASSIGN_TENANT_USER_ACTION.REQUEST({
        userId,
        projectId,
        organizationId,
      })
    );
    return projectRequest
      .deassignUser(userId, projectId)
      .then(() => {
        return dispatch(
          DEASSIGN_TENANT_USER_ACTION.SUCCESS({
            userId,
            projectId,
            organizationId,
          })
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        throw dispatch(
          DEASSIGN_TENANT_USER_ACTION.ERROR({
            userId,
            projectId,
            organizationId,
            error: error.message,
          })
        );
      });
  };
}

export function assignBeneficiaryUser(userId, projectId, organizationId) {
  required({ userId, projectId, organizationId });
  return (dispatch) => {
    dispatch(
      ASSIGN_BENEFICIARY_USER_ACTION.REQUEST({
        userId,
        projectId,
        organizationId,
      })
    );
    return projectRequest
      .assignUser(userId, projectId)
      .then(() => {
        return dispatch(
          ASSIGN_BENEFICIARY_USER_ACTION.SUCCESS({
            userId,
            projectId,
            organizationId,
          })
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        throw dispatch(
          ASSIGN_BENEFICIARY_USER_ACTION.ERROR({
            userId,
            projectId,
            organizationId,
            error: error.message,
          })
        );
      });
  };
}

export function deassignBeneficiaryUser(userId, projectId, organizationId) {
  required({ userId, projectId, organizationId });
  return (dispatch) => {
    dispatch(
      DEASSIGN_BENEFICIARY_USER_ACTION.REQUEST({
        userId,
        projectId,
        organizationId,
      })
    );
    return projectRequest
      .deassignUser(userId, projectId)
      .then(() => {
        return dispatch(
          DEASSIGN_BENEFICIARY_USER_ACTION.SUCCESS({
            userId,
            projectId,
            organizationId,
          })
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        throw dispatch(
          DEASSIGN_BENEFICIARY_USER_ACTION.ERROR({
            userId,
            projectId,
            organizationId,
            error: error.message,
          })
        );
      });
  };
}

const adaptedLocation = (location) => {
  const newLocation = { ...location };
  if (!newLocation.buildingLevel) {
    newLocation.buildingLevel = newLocation.level;
  }

  if (!newLocation.buildingRooms) {
    newLocation.buildingRooms = newLocation.rooms;
  }

  if (!newLocation.id) {
    newLocation.id = newLocation.key;
  }

  delete newLocation.key;
  delete newLocation.level;
  delete newLocation.rooms;

  return newLocation;
};

export function addTenantLocation(location, projectId, organizationId) {
  required({ location, projectId, organizationId });
  // eslint-disable-next-line no-param-reassign
  location = adaptedLocation(location);
  return (dispatch) => {
    dispatch(
      ADD_TENANT_LOCATION_ACTION.REQUEST({
        location,
        projectId,
        organizationId,
      })
    );
    return buildingRequest
      .addTenantLocation(
        location.building.id,
        location.buildingLevel.id,
        location.buildingRooms.map((room) => room.id),
        projectId,
        organizationId
      )
      .then(() => {
        return dispatch(
          ADD_TENANT_LOCATION_ACTION.SUCCESS({
            location,
            projectId,
            organizationId,
          })
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        throw dispatch(
          ADD_TENANT_LOCATION_ACTION.ERROR({
            location,
            projectId,
            organizationId,
            error: error.message,
          })
        );
      });
  };
}

export function removeTenantLocation(location, projectId, organizationId) {
  required({ location, projectId, organizationId });
  // eslint-disable-next-line no-param-reassign
  location = adaptedLocation(location);
  return (dispatch) => {
    dispatch(
      REMOVE_TENANT_LOCATION_ACTION.REQUEST({
        location,
        projectId,
        organizationId,
      })
    );
    return buildingRequest
      .removeTenantLocation(
        location.building.id,
        location.buildingLevel.id,
        projectId,
        organizationId
      )
      .then(() => {
        return dispatch(
          REMOVE_TENANT_LOCATION_ACTION.SUCCESS({
            location,
            projectId,
            organizationId,
          })
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        throw dispatch(
          REMOVE_TENANT_LOCATION_ACTION.ERROR({
            location,
            projectId,
            organizationId,
            error: error.message,
          })
        );
      });
  };
}

export function getProjectLayoutFiles(projectId) {
  required({ projectId });
  return (dispatch) => {
    dispatch(
      GET_PROJECT_LAYOUT_FILES_ACTION.REQUEST({
        projectId,
      })
    );
    return projectLayoutRequest
      .getLayoutFiles(projectId)
      .then((layoutFiles) => {
        return dispatch(
          GET_PROJECT_LAYOUT_FILES_ACTION.SUCCESS({
            projectId,
            layoutFiles,
            resourceModel: FileModel,
          })
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        throw dispatch(
          GET_PROJECT_LAYOUT_FILES_ACTION.ERROR({
            projectId,
            error: error.message,
          })
        );
      });
  };
}
export function deleteProjectLayoutFiles(
  projectId,
  fileId,
  meta = { shouldFetchLayoutFiles: false }
) {
  required({ projectId, fileId });
  return (dispatch) => {
    dispatch(
      DELETE_PROJECT_LAYOUT_FILES_ACTION.REQUEST({
        projectId,
      })
    );
    return projectLayoutRequest
      .deleteLayoutFiles(projectId, fileId)
      .then(() => {
        if (meta.shouldFetchLayoutFiles) {
          dispatch(getProjectLayoutFiles(projectId));
        }
        return dispatch(
          DELETE_PROJECT_LAYOUT_FILES_ACTION.SUCCESS({
            projectId,
            fileId,
          })
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        throw dispatch(
          DELETE_PROJECT_LAYOUT_FILES_ACTION.ERROR({
            projectId,
            fileId,
            error: error.message,
          })
        );
      });
  };
}

export function createProjectLayoutFiles(
  projectId,
  filesIds,
  meta = { shouldFetchLayoutFiles: false }
) {
  required({ projectId, filesIds });
  return (dispatch) => {
    dispatch(
      CREATE_PROJECT_LAYOUT_FILES_ACTION.REQUEST({
        projectId,
      })
    );
    return projectLayoutRequest
      .createLayoutFiles(projectId, filesIds)
      .then(({ data }) => {
        if (meta.shouldFetchLayoutFiles) {
          dispatch(getProjectLayoutFiles(projectId));
        }
        return dispatch(
          CREATE_PROJECT_LAYOUT_FILES_ACTION.SUCCESS({
            projectId,
            layoutFiles: {
              data,
            },
          })
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        throw dispatch(
          CREATE_PROJECT_LAYOUT_FILES_ACTION.ERROR({
            projectId,
            error: error.message,
          })
        );
      });
  };
}
