import ApiResourceRequest, { API_METHODS } from '../ApiResourceRequest';
import { required } from '../../../utils/helpers';
import ListingResponse from '../ListingResponse';
import Pager from '../../helpers/Pager';
import { FileRequest } from '../FileRequest';
import { DOCUMENT_FILE_EXTENSIONS } from '../../../utils/constants';

export class EquipmentRequest extends ApiResourceRequest {
  get apiResource() {
    return 'equipments';
  }

  get dynamicIds() {
    return {
      PROJECT_ID: '{projectId}',
      CATEGORY_ID: '{categoryId}',
      CATEGORY_GROUP_ID: '{categoryGroupId}',
      EQUIPMENT_ID: '{equipmentId}',
    };
  }

  get routes() {
    return {
      ...super.routes,
      GET_BY_PROJECT_ID: `${this.apiRoute}/by-project-id/${this.dynamicIds.PROJECT_ID}`,
      GET_BY_CATEGORY_GROUP_ID: `${this.apiRoute}/by-category-group-id/${this.dynamicIds.CATEGORY_GROUP_ID}`,
      GET_CATEGORIES_BY_PROJECT_ID: `${this.apiRoute}/categories/by-project-id/${this.dynamicIds.PROJECT_ID}`,
      GET_PMP_EVENTS: `${this.apiUrl}/maintenance-procedures/by-equipment-id/${this.dynamicIds.EQUIPMENT_ID}/pmp-events`,
      ENABLE: `${this.apiRoute}/${this.dynamicIds.EQUIPMENT_ID}/enable`,
      DISABLE: `${this.apiRoute}/${this.dynamicIds.EQUIPMENT_ID}/disable`,
      ARCHIVE: `${this.apiRoute}/${this.dynamicIds.EQUIPMENT_ID}/archive`,
      EQUIPMENT_LOGS: `${this.apiRoute}/${this.dynamicIds.EQUIPMENT_ID}/logs`,
      GET_REPORT_FILE: `${this.apiRoute}/by-project-id/${this.dynamicIds.PROJECT_ID}/export`,
    };
  }

  async getByProjectId(projectId, pager = new Pager(), query = {}) {
    pager && Pager.check(pager);
    required({ projectId });
    const queryString = this.buildQueryString(
      this.getPaginationParams(pager),
      this.getRelationParams(query.relations),
      this.getFilterParams(query.filter, true),
      this.getOrderParams(query.order),
      this.getExtraQueryParams(query.extra)
    );

    const response = await this.request(
      API_METHODS.GET,
      this.routes.GET_BY_PROJECT_ID.replace(
        this.dynamicIds.PROJECT_ID,
        projectId
      ) + queryString
    );

    return new ListingResponse(response?.data);
  }

  async getEquipmentsCategories(projectId, query = {}) {
    required({ projectId });
    const queryString = this.buildQueryString(
      this.getRelationParams(query.relations),
      this.getFilterParams(query.filter),
      this.getOrderParams(query.order),
      this.getExtraQueryParams(query.extra)
    );

    const response = await this.request(
      API_METHODS.GET,
      this.routes.GET_CATEGORIES_BY_PROJECT_ID.replace(
        this.dynamicIds.PROJECT_ID,
        projectId
      ) + queryString
    );

    return response?.data;
  }

  async getByCategoryGroupId(categoryGroupId, pager = new Pager(), query = {}) {
    pager && Pager.check(pager);
    required({ categoryGroupId });
    const queryString = this.buildQueryString(
      this.getPaginationParams(pager),
      this.getRelationParams(query.relations),
      this.getFilterParams(query.filter),
      this.getOrderParams(query.order)
    );

    const response = await this.request(
      API_METHODS.GET,
      this.routes.GET_BY_CATEGORY_GROUP_ID.replace(
        this.dynamicIds.CATEGORY_GROUP_ID,
        categoryGroupId
      ) + queryString
    );

    return new ListingResponse(response?.data);
  }

  async getPmpEvents(equipmentId, query) {
    required({ equipmentId });

    const queryString = this.buildQueryString(
      this.getFilterParams(query.filter),
      this.getExtraQueryParams(query.extra)
    );

    const response = await this.request(
      API_METHODS.GET,
      this.routes.GET_PMP_EVENTS.replace(
        this.dynamicIds.EQUIPMENT_ID,
        equipmentId
      ) + queryString
    );

    return new ListingResponse(response?.data);
  }

  async enable(equipmentId, toggleDate) {
    required({ equipmentId, toggleDate });

    const response = await this.request(
      API_METHODS.POST,
      this.routes.ENABLE.replace(this.dynamicIds.EQUIPMENT_ID, equipmentId),
      { toggleDate }
    );

    return response?.data;
  }

  async disable(equipmentId, toggleDate) {
    required({ equipmentId, toggleDate });

    const response = await this.request(
      API_METHODS.POST,
      this.routes.DISABLE.replace(this.dynamicIds.EQUIPMENT_ID, equipmentId),
      { toggleDate }
    );

    return response?.data;
  }

  async archive(equipmentId) {
    required({ equipmentId });

    const response = await this.request(
      API_METHODS.POST,
      this.routes.ARCHIVE.replace(this.dynamicIds.EQUIPMENT_ID, equipmentId),
      {}
    );

    return response?.data;
  }

  async getLogs(equipmentId, pager, query = {}) {
    required({ equipmentId });
    pager && Pager.check(pager);

    const queryString = this.buildQueryString(
      this.getPaginationParams(pager),
      this.getExtraQueryParams(query)
    );

    const response = await this.request(
      API_METHODS.GET,
      this.routes.EQUIPMENT_LOGS.replace(
        this.dynamicIds.EQUIPMENT_ID,
        equipmentId
      ) + queryString,
      {}
    );

    return response?.data;
  }

  async getReportFile(projectId, query = {}) {
    required({
      projectId,
    });

    const queryString = this.buildQueryString(
      this.getExtraQueryParams({
        ...(query.extra || {}),
      }),
      this.getFilterParams(query.filter, true)
    );

    const response = await this.request(
      API_METHODS.GET,
      this.routes.GET_REPORT_FILE.replace(
        this.dynamicIds.PROJECT_ID,
        projectId
      ) + queryString,
      {},
      {
        responseType: 'blob',
      }
    );

    return response.data;
  }

  async downloadReportFile(projectId, fileName, query = {}) {
    required({ projectId, fileName });

    console.log('query', query);

    const reportBlob = await this.getReportFile(projectId, query);

    return FileRequest.downloadFileViaLink(reportBlob, {
      fileName,
      fileExtension: DOCUMENT_FILE_EXTENSIONS.excel,
    });
  }
}

export const equipmentRequest = new EquipmentRequest();
