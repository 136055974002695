export default {
  project: {
    status: 'Stare',
    alerts: 'Alerte',
    projectInfo: 'Project Info',
    lastUpdate: 'Ultima modificare',
    activeProjects: 'Proiecte active',
    projects: 'Proiecte',
    noProjectsFound: 'Nu au fost găsite proiecte',
    drafts: 'Drafts',
    archived: 'Arhivate',
    buildings: 'Clădiri',
    equipment: 'Echipamente',
    procedures: 'Proceduri',
    standardProcedures: 'Proceduri standard',
    additionalProcedures: 'Additional procedures',
    activateProject: 'Activează proiectul',
    conversations: {
      beneficiary: 'Beneficiar',
      management: 'Management',
      staff: 'Personal',
    },
    generalProjectForm: {
      generalProjectInformation: 'Informații generale despre proiect',
      projectName: 'Denumirea proiectului',
      mediaGallery: 'Galerie media',
      startedDate: 'Data începerii',
      endDate: 'Data de încheiere',
      address: 'Adresa',
      country: 'Țară',
      state: 'Stat',
      city: 'Oraș',
      postalCode: 'Cod poștal',
      addressOne: 'Adresa 1',
      addressTwo: 'Adresa 2',
      projectDescription: 'Descrierea proiectului',
      projectImage: 'Imaginea proiectului',
    },
    generalContractorForm: {
      name: 'Nume',
      address: 'Adresă',
      city: 'Oraș',
      country: 'Țară',
      addressLineOne: 'Adresă linia 1',
      addressLineTwo: 'Adresă linia 2 (opțional)',
      contact: 'Contact',
      companyRepresentative: 'Reprezentant companie',
      email: 'Email',
      emailAddress: 'Adresă email',
      phone: 'Telefon',
      warranty: 'Garanție',
      uploadWarrantyDocuments: 'Încară documentele garanției',
      uploadProjectImage: 'Încărcați imaginea proiectului',
      contractorName: 'Contractor nume',
      contactInformation: 'Contact informatie',
      warrantyDocuments: 'Documentele garanției',
    },
    generalInformationForm: {
      name: 'Nume',
      address: 'Adresă',
      city: 'Oraș',
      country: 'Țară',
      addressLineOne: 'Adresă linia 1',
      addressLineTwo: 'Adresă linia 2 (opțional)',
      selectOrAddBeneficiary: 'Selectează sau adaugă beneficiar',
      selectBeneficiary: 'Selecteză beneficiar',
      beneficiary: {
        types: {
          beneficiary: 'Beneficiar',
          provider: 'Furnizor',
          tenant: 'Chiariaș',
        },
      },
      manager: 'manager',
      selectOrAddBuildingManager: 'Selectează sau adaugă manager clădire',
      selectBuildingManager: 'Selectează manager clădire',
    },
    tenant: {
      addTenantInformation: 'Adaugă informatii chiriaș',
      locations: 'Locații',
      officeManagers: 'Manageri de birou',
      addAnotherLocation: 'Adaugă încă o locație',
      saveTenant: 'Salvează chiriaș',
      saveNewTenant: 'Salvează chiriaș nou',
      selectOfficeManager: 'Selectează un manager de birou',
      removeTenant: 'Eliminați chiriașul',
      noDataText: 'Hit the blue plus button and start adding tenants',
      noTenants: 'No Tenants',
    },
    activateDraftModal: {
      cancel: 'Nu, anulează',
      confirm: 'Da, activează!',
      message: 'Ești sigur că vrei să activezi acest proiect?',
    },
    deleteDraftModal: {
      cancel: 'Nu, anulează',
      confirm: 'Da, șterge!',
      message:
        'Ești sigur că vrei să ștergi acest proiect și toate informațiile asociate? Acțiunea este ireversibilă.',
    },
    assignedProjects: 'Proiecte atribuite',
    noAssignedProjects: 'Nu ai niciun proiect atribuit',
    noActiveProjects: 'Nu ai niciun proiect activ',
    notFound: 'Proiectul cu id-ul dat nu a fost gasit',
    teamMembers: 'Membrii echipei',
    tenants: 'Chiriași',
    beneficiary: 'Beneficiar',
    projectName: {
      itself: 'Denumirea proiectului',
      '*': 'Denumirea proiectului',
    },
    giveProjectAName: 'Give project a name',
    startDate: {
      itself: 'Start date',
      '*': 'Start date *',
    },
    endDate: {
      itself: 'End date',
      '*': 'End date *',
    },
    projectDurationError:
      'Project duration should be at least 6 months and not greater than 2 years',
    summary: 'Summary',
    projectDescription: 'Project description',
    media: 'Media',
    generalContractor: 'Antreprenor general',
    dangerZone: 'Zona periculoasă',
    archiveModal: {
      cancel: 'No, cancel',
      confirm: 'Yes, archive!',
      message: 'Are you sure you want to archive this project?',
    },
    projectActivation: {
      itself: 'Project activation',
      button: 'Activate project',
      message:
        'Activarea proiectului dumneavoastră va pune în mișcare toate componentele acestuia. Odată activat, proiectul va fi complet operațional și vizibil pentru toate părțile interesate. Vă rugăm să vă asigurați că toate detaliile proiectului sunt exacte și complete înainte de activare.',
    },
    deletingProject: {
      itself: 'Deleting the project',
      button: 'Delete project',
      message:
        'Ștergerea acestui proiect va elimina definitiv toate datele asociate. Această acțiune este ireversibilă. Vă rugăm să vă asigurați că ați salvat toate informațiile necesare înainte de a continua cu ștergerea.',
    },
    archiveProject: {
      itself: 'Proiect arhivat’',
      button: 'Proiect arhivat’',
      message:
        'Arhivarea acestui proiect va păstra istoricul și datele sale, inclusiv echipamentele, procedurile de întreținere, în timp ce îl va scoate din operațiunile active. Proiectul nu va mai fi editabil sau vizibil în lista de proiecte active, dar puteți accesa întregul său istoric pentru referință sau scopuri de audit. Arhivarea este o opțiune potrivită pentru proiectele finalizate pe care doriți să le păstrați în scopuri de înregistrare.',
    },
    dashboard: {
      itself: 'Dashboard',
      dailyCheck: 'Verificare zilnică',
      preventiveMaintenance: 'Întreținerea preventivă',
      tickets: 'Bilete',
      createTicket: 'Creați un bilet',
      maintenancePlan: 'Plan de întreținere',
      warrantyNotes: 'Note de garantie',
      createWarrantyNotes: 'Creați o notă de garanție',
      projectBoard: {
        noItems: 'Nicio sarcină încă',
        blockTitle: 'Tabloul de proiect',
        modalTitleCreate: 'Creați o sarcină',
        modalTitleEdit: 'Editați sarcina',
        infoModal: {
          markTodo: 'Marcați ca tot',
          markCompleted: 'Marcați ca finalizat',
        },
        types: {
          personal: 'Sarcina personală',
          team: 'Sarcina echipei',
        },
        statuses: {
          toDo: 'Todo',
          completed: 'Efectuat',
        },
        tabs: {
          team: 'Echipă',
          personal: 'Personal',
        },
        form: {
          timestampReminder: {
            itself: 'Memento de dată',
            error:
              'Data de memento trebuie să fie mai mică decât data de începere',
          },
        },
        saveTask: 'Salvați sarcina',
      },
      procurementOrders: 'Comenzi de achiziții',
      createProcurementOrders: 'Creați comanda de achiziție',
      createActivityRegistry: 'Creați un registru de activități',
      announcements: 'Anunturi',
      weather: 'Vreme',
      next7Days: 'Următoarele 7 zile',
    },
    exitProject: 'Ieșire din proiect',
  },
};
