import CityModel from 'api/models/CityModel';
import { buildQueryKey } from 'utils/helpers';
import { STATUS } from 'utils/constants';
import {
  ACTIVATE,
  ADD_TENANT_LOCATION,
  ARCHIVE,
  ASSIGN_BENEFICIARY_USER,
  ASSIGN_TEAM_MEMBER_USER,
  ASSIGN_TENANT_USER,
  CREATE,
  DEASSIGN_BENEFICIARY_USER,
  DEASSIGN_TEAM_MEMBER_USER,
  DEASSIGN_TENANT_USER,
  DELETE,
  FIND,
  GET,
  GET_ASSIGNED,
  GET_PROJECT_LAYOUT_FILES,
  GET_PROJECT_USERS_STATISTICS,
  GET_PUBLIC_DATA_TYPES,
  GET_STATISTICS,
  PROJECT_LIST_TABS_SET_STATE_COUNTS,
  REMOVE_TENANT_LOCATION,
  UNARCHIVE,
  UPDATE,
} from './constants';
import {
  addToDictionary,
  addToList,
  changeListStatus,
  handleSortLocations,
  hasPageSizeChanged,
  resetState,
} from '../helpers';
import {
  GET_PROJECT_BENEFICIARY_USERS,
  GET_PROJECT_TEAM_MEMBER_USERS,
  GET_PROJECT_TENANT_USERS,
} from '../user/constants';
import {
  CHANGE_ACTIVE_BUILDING_ID,
  CREATE_BUILDING,
  CREATE_BUILDING_LEVEL,
  DELETE_BUILDING,
  DELETE_BUILDING_LEVEL,
  DUPLICATE_BUILDING_LEVEL,
  GET_BUILDINGS,
  LINK_BUILDING_LEVEL,
  MOVE_BUILDING_LEVEL,
  UNLINK_BUILDING_LEVEL,
  UPDATE_BUILDING,
  UPDATE_BUILDING_LEVEL,
} from './building/actionTypes';

/**
 *
 * @type {{list: {default: {}, pagination: {total: number, pageNumber: null, pageSize: null}, meta: {currentKey: string}}, dictionary: {}}}
 */
const initialState = {
  dictionary: {},
  list: {
    meta: {
      currentKey: 'default',
    },
    pagination: {
      pageNumber: null,
      pageSize: null,
      total: 0,
    },
    default: {},
  },
  projectListTabsStateCounts: {
    active: 0,
    draft: 0,
    archived: 0,
  },
  publicDataDictionary: {},
  usersStatistics: {
    // format:
    // [projectId]: {
    //   teamMemberCount: 0,
    //   tenantMemberCount: 0,
    //   beneficiaryMemberCount: 0,
    // },
  },
  teamMemberUsers: {
    // format:
    // [projectId]: [1,2,3, ...ids (resource taken from user dictionary)],
  },
  tenantUsers: {
    // format:
    // [projectId]: [
    //  [orgId]: { (org saved in organization dictionary)
    //    userIds: [1,2,3, ...ids (resource taken from user dictionary)],
    //    locations: [{}, {}, ...locations],
    //  },
    // ],
  },
  layoutFiles: {},
  beneficiaryUsers: {
    // format:
    // [projectId]: {
    //  [orgId]: [1,2,3, ...ids (resource taken from user dictionary)], (org saved in organization dictionary)
    // },
  },
  buildings: {
    getIsLoading: false,
    // format:
    // [projectId]: {
    //  activeBuildingId: 23,
    //  buildingDictionary: {
    //    [buildingId]: {"name": "Building", levels: [{"name", ..., rooms: [{"name", ...}] }]},
    //  }
    // },
  },
};

export default function projectReducer(state = initialState, action) {
  let queryKey;
  let listStatus;
  let error;
  let id;
  let resourceModel;
  let resetToDefault;
  let projectId;
  let userId;
  let orgId;
  let buildingId;
  let levelId;
  let previousTeamMemberCount;
  let previousTeamMemberUsers;
  let previousTenantMemberCount;
  let previousTenantUserIds;
  let previousBeneficiaryMemberCount;
  let previousBeneficiaryUsers;
  let tenantLocation;
  let previousLocations;
  let updatedLocations;
  let pager;
  let buildingData;
  let updatedBuildingDictionary;

  switch (action.type) {
    case GET.REQUEST:
      queryKey = buildQueryKey(action.payload.query);
      pager = action.payload.pager;
      listStatus = state.list?.[queryKey]?.[pager?.pageNumber]?.status;
      resetToDefault = action.payload.resetToDefault;

      if (
        hasPageSizeChanged(state.list.pagination.pageSize, pager?.pageSize) ||
        resetToDefault
      ) {
        return resetState(state, initialState, {
          queryKey,
          pager,
        });
      }

      if (
        [STATUS.RELOADING, STATUS.SUCCESS, STATUS.CACHED].includes(listStatus)
      ) {
        return changeListStatus(state, { pager, queryKey }, STATUS.RELOADING);
      }
      return changeListStatus(state, { pager, queryKey }, STATUS.LOADING);
    case GET.SUCCESS:
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: addToDictionary(
          state.dictionary,
          action.payload.resources,
          resourceModel
        ),
        list: addToList(state.list, action.payload),
      };
    case GET.ERROR:
      queryKey = buildQueryKey(action.payload.query);
      pager = action.payload.pager;
      error = action.payload.error;
      listStatus = state.list?.[queryKey]?.[pager?.pageNumber]?.status;

      return changeListStatus(
        state,
        { pager, queryKey, error },
        listStatus === STATUS.RELOADING ? STATUS.CACHED : STATUS.ERROR
      );

    case GET_STATISTICS.REQUEST:
      queryKey = buildQueryKey(action.payload.query);
      pager = action.payload.pager;
      listStatus = state.list?.[queryKey]?.[pager?.pageNumber]?.status;
      resetToDefault = action.payload.resetToDefault;

      if (
        hasPageSizeChanged(state.list.pagination.pageSize, pager?.pageSize) ||
        resetToDefault
      ) {
        return resetState(state, initialState, {
          queryKey,
          pager,
        });
      }

      if (
        [STATUS.RELOADING, STATUS.SUCCESS, STATUS.CACHED].includes(listStatus)
      ) {
        return changeListStatus(state, { pager, queryKey }, STATUS.RELOADING);
      }
      return changeListStatus(state, { pager, queryKey }, STATUS.LOADING);
    case GET_STATISTICS.SUCCESS:
      resourceModel = action.payload.resourceModel;
      // eslint-disable-next-line no-param-reassign
      action.payload.resources = action.payload.resources.map((project) => ({
        ...project,
        fromStatistics: true,
      }));

      return {
        ...state,
        dictionary: addToDictionary(
          state.dictionary,
          action.payload.resources,
          resourceModel
        ),
        list: addToList(state.list, action.payload),
      };
    case GET_STATISTICS.ERROR:
      queryKey = buildQueryKey(action.payload.query);
      error = action.payload.error;
      pager = action.payload.pager;
      listStatus = state.list?.[queryKey]?.[pager?.pageNumber]?.status;

      return changeListStatus(
        state,
        { pager, queryKey, error },
        listStatus === STATUS.RELOADING ? STATUS.CACHED : STATUS.ERROR
      );

    case GET_ASSIGNED.REQUEST:
      queryKey = buildQueryKey(action.payload.query);
      pager = action.payload.pager;
      listStatus = state.list?.[queryKey]?.[pager?.pageNumber]?.status;
      resetToDefault = action.payload.resetToDefault;

      if (
        hasPageSizeChanged(state.list.pagination.pageSize, pager?.pageSize) ||
        resetToDefault
      ) {
        return resetState(state, initialState, {
          queryKey,
          pager,
        });
      }

      if (
        [STATUS.RELOADING, STATUS.SUCCESS, STATUS.CACHED].includes(listStatus)
      ) {
        return changeListStatus(state, { pager, queryKey }, STATUS.RELOADING);
      }
      return changeListStatus(state, { pager, queryKey }, STATUS.LOADING);
    case GET_ASSIGNED.SUCCESS:
      resourceModel = action.payload.resourceModel;
      // eslint-disable-next-line no-param-reassign
      action.payload.resources = action.payload.resources.map((project) => ({
        ...project,
        fromStatistics: true,
      }));

      return {
        ...state,
        dictionary: addToDictionary(
          state.dictionary,
          action.payload.resources,
          resourceModel
        ),
        list: addToList(state.list, action.payload),
      };
    case GET_ASSIGNED.ERROR:
      queryKey = buildQueryKey(action.payload.query);
      error = action.payload.error;
      pager = action.payload.pager;
      listStatus = state.list?.[queryKey]?.[pager?.pageNumber]?.status;

      return changeListStatus(
        state,
        { pager, queryKey, error },
        listStatus === STATUS.RELOADING ? STATUS.CACHED : STATUS.ERROR
      );

    case GET_PUBLIC_DATA_TYPES.REQUEST: {
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        publicDataDictionary: {
          ...state.publicDataDictionary,
          [id]: state.publicDataDictionary?.[id]
            ? new resourceModel(
                { ...state.publicDataDictionary[id] },
                STATUS.LOADING
              )
            : new resourceModel({}, STATUS.LOADING),
        },
      };
    }
    case GET_PUBLIC_DATA_TYPES.SUCCESS: {
      const { resource } = action.payload;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        publicDataDictionary: addToDictionary(
          state.publicDataDictionary,
          [resource],
          resourceModel
        ),
      };
    }
    case GET_PUBLIC_DATA_TYPES.ERROR: {
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        publicDataDictionary: {
          ...state.publicDataDictionary,
          [id]: new resourceModel(
            { ...state.publicDataDictionary[id] },
            STATUS.ERROR,
            action.payload.error
          ),
        },
      };
    }

    case FIND.REQUEST:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: state.dictionary?.[id]
            ? new resourceModel({ ...state.dictionary[id] }, STATUS.LOADING)
            : new resourceModel({}, STATUS.LOADING),
        },
      };
    case FIND.SUCCESS:
      const { resource } = action.payload;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [resource.id]: new resourceModel({
            ...state.dictionary[resource.id],
            ...resource,
            fromStatistics: false,
            findFetched: true,
          }),
        },
      };
    case FIND.ERROR:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: new resourceModel(
            { ...state.dictionary[id] },
            STATUS.ERROR,
            action.payload.error
          ),
        },
      };

    case CREATE.REQUEST:
      return state;
    case CREATE.SUCCESS:
      return state;
    case CREATE.ERROR:
      return state;

    case UPDATE.REQUEST:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: state.dictionary?.[id]
            ? new resourceModel({ ...state.dictionary[id] }, STATUS.LOADING)
            : new resourceModel({}, STATUS.LOADING),
        },
      };
    case UPDATE.SUCCESS:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;
      const { resourceAttributes } = action.payload;

      const updatedAttributes = {};
      Object.entries(resourceAttributes).forEach(([key, value]) => {
        if (value) {
          updatedAttributes[key] = value;
        }
      });
      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: resourceAttributes
            ? new resourceModel({
                ...state.dictionary[id],
                ...updatedAttributes,
                city: new CityModel({
                  ...state.dictionary[id].city,
                  ...resourceAttributes.city,
                  ...(resourceAttributes.state
                    ? {
                        stateIsoCode: resourceAttributes.state?.stateIsoCode,
                        stateName: resourceAttributes.state?.stateName,
                      }
                    : {}),
                }),
              })
            : new resourceModel({ ...state.dictionary[id] }, STATUS.SUCCESS),
        },
      };
    case UPDATE.ERROR:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: new resourceModel(
            { ...state.dictionary[id] },
            STATUS.ERROR,
            action.payload.error
          ),
        },
      };

    case DELETE.REQUEST:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: state.dictionary?.[id]
            ? new resourceModel({ ...state.dictionary[id] }, STATUS.LOADING)
            : new resourceModel({}, STATUS.LOADING),
        },
      };
    case DELETE.SUCCESS:
      return state;
    case DELETE.ERROR:
      id = action.payload.id;
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [id]: new resourceModel(
            { ...state.dictionary[id] },
            STATUS.ERROR,
            action.payload.error
          ),
        },
      };

    case ACTIVATE.REQUEST:
      return state;
    case ACTIVATE.SUCCESS:
      return state;
    case ACTIVATE.ERROR:
      return state;

    case ARCHIVE.REQUEST:
      return state;
    case ARCHIVE.SUCCESS:
      return state;
    case ARCHIVE.ERROR:
      return state;

    case UNARCHIVE.REQUEST:
      return state;
    case UNARCHIVE.SUCCESS:
      return state;
    case UNARCHIVE.ERROR:
      return state;

    case GET_PROJECT_USERS_STATISTICS.REQUEST:
      return state;
    case GET_PROJECT_USERS_STATISTICS.SUCCESS:
      return {
        ...state,
        usersStatistics: {
          ...state.usersStatistics,
          [action.payload.projectId]: action.payload.usersStatistics,
        },
      };
    case GET_PROJECT_USERS_STATISTICS.ERROR:
      return state;

    case GET_PROJECT_LAYOUT_FILES.REQUEST:
      return {
        ...state,
        layoutFiles: {
          [action.payload.projectId]: {
            ...state.layoutFiles[action.payload.projectId],
            status: state.layoutFiles[action.payload.projectId]
              ? STATUS.RELOADING
              : STATUS.LOADING,
          },
        },
      };
    case GET_PROJECT_LAYOUT_FILES.SUCCESS:
      resourceModel = action.payload.resourceModel;

      return {
        ...state,
        layoutFiles: {
          ...state.layoutFiles,
          [action.payload.projectId]: {
            status: STATUS.SUCCESS,
            files: action.payload.layoutFiles.map(
              (layoutFile) => new resourceModel(layoutFile)
            ),
          },
        },
      };
    case GET_PROJECT_LAYOUT_FILES.ERROR:
      return {
        ...state,
        layoutFiles: {
          ...state.layoutFiles,
          [action.payload.projectId]: {
            status: STATUS.ERROR,
          },
        },
      };

    case PROJECT_LIST_TABS_SET_STATE_COUNTS:
      return {
        ...state,
        projectListTabsStateCounts: {
          ...state.projectListTabsStateCounts,
          ...action.payload,
        },
      };

    case GET_PROJECT_TEAM_MEMBER_USERS.SUCCESS:
      projectId = action.payload.projectId;

      return {
        ...state,
        teamMemberUsers: {
          ...state.teamMemberUsers,
          [projectId]:
            action.payload.resources[0]?.users.map((user) => user.id) ?? [],
        },
      };

    case GET_PROJECT_TENANT_USERS.SUCCESS:
      projectId = action.payload.projectId;
      const { buildingModel, levelModel, roomModel } = action.payload;

      return {
        ...state,
        tenantUsers: {
          ...state.tenantUsers,
          [projectId]: action.payload.resources.reduce(
            (accumulator, organization) => {
              return {
                ...accumulator,
                [organization.id]: {
                  userIds: organization.users.map((user) => user.id),
                  locations: organization.locations
                    .sort(handleSortLocations)
                    .map((location) => ({
                      ...location,
                      building: new buildingModel(location.building),
                      buildingLevel: new levelModel(location.buildingLevel),
                      buildingRooms: location.buildingRooms.map(
                        (room) => new roomModel(room)
                      ),
                    })),
                },
              };
            },
            {}
          ),
        },
      };

    case GET_PROJECT_BENEFICIARY_USERS.SUCCESS:
      projectId = action.payload.projectId;

      return {
        ...state,
        beneficiaryUsers: {
          ...state.beneficiaryUsers,
          [projectId]: action.payload.resources.reduce(
            (accumulator, organization) => {
              return {
                ...accumulator,
                [organization.id]: organization.users.map((user) => user.id),
              };
            },
            {}
          ),
        },
      };

    case ASSIGN_TEAM_MEMBER_USER.SUCCESS:
      projectId = action.payload.projectId;
      userId = action.payload.userId;
      previousTeamMemberCount =
        state.usersStatistics[projectId]?.teamMemberCount;
      previousTeamMemberUsers = state.teamMemberUsers[projectId];

      return {
        ...state,
        usersStatistics: {
          ...state.usersStatistics,
          [projectId]: {
            ...state.usersStatistics[projectId],
            teamMemberCount: Number.isInteger(previousTeamMemberCount)
              ? previousTeamMemberCount + 1
              : 1,
          },
        },
        teamMemberUsers: {
          ...state.teamMemberUsers,
          [projectId]: Array.isArray(previousTeamMemberUsers)
            ? [...previousTeamMemberUsers, userId]
            : [userId],
        },
      };

    case DEASSIGN_TEAM_MEMBER_USER.SUCCESS:
      projectId = action.payload.projectId;
      userId = action.payload.userId;
      previousTeamMemberCount =
        state.usersStatistics[projectId]?.teamMemberCount;
      previousTeamMemberUsers = state.teamMemberUsers[projectId];

      return {
        ...state,
        usersStatistics: {
          ...state.usersStatistics,
          [projectId]: {
            ...state.usersStatistics[projectId],
            teamMemberCount: Number.isInteger(previousTeamMemberCount)
              ? previousTeamMemberCount - 1
              : 0,
          },
        },
        teamMemberUsers: {
          ...state.teamMemberUsers,
          [projectId]: Array.isArray(previousTeamMemberUsers)
            ? previousTeamMemberUsers.filter(
                (teamMemberId) => teamMemberId !== userId
              )
            : [],
        },
      };

    case ASSIGN_TENANT_USER.SUCCESS:
      projectId = action.payload.projectId;
      userId = action.payload.userId;
      orgId = action.payload.organizationId;
      previousTenantMemberCount =
        state.usersStatistics[projectId]?.tenantMemberCount;
      previousTenantUserIds = state.tenantUsers[projectId]?.[orgId]?.userIds;

      return {
        ...state,
        usersStatistics: {
          ...state.usersStatistics,
          [projectId]: {
            ...state.usersStatistics[projectId],
            tenantMemberCount:
              // eslint-disable-next-line no-nested-ternary
              !previousTenantUserIds?.length
                ? Number.isInteger(previousTenantMemberCount)
                  ? previousTenantMemberCount + 1
                  : 1
                : previousTenantMemberCount,
          },
        },
        tenantUsers: {
          ...state.tenantUsers,
          [projectId]: {
            ...state.tenantUsers[projectId],
            [orgId]: {
              ...state.tenantUsers[projectId]?.[orgId],
              userIds: Array.isArray(previousTenantUserIds)
                ? [...previousTenantUserIds, userId]
                : [userId],
            },
          },
        },
      };

    case DEASSIGN_TENANT_USER.SUCCESS:
      projectId = action.payload.projectId;
      userId = action.payload.userId;
      orgId = action.payload.organizationId;
      previousTenantMemberCount =
        state.usersStatistics[projectId]?.tenantMemberCount;
      previousTenantUserIds = state.tenantUsers[projectId]?.[orgId]?.userIds;
      const newTenantUserIds = Array.isArray(previousTenantUserIds)
        ? previousTenantUserIds.filter(
            (tenantUserId) => tenantUserId !== userId
          )
        : [];

      return {
        ...state,
        usersStatistics: {
          ...state.usersStatistics,
          [projectId]: {
            ...state.usersStatistics[projectId],
            // eslint-disable-next-line no-nested-ternary
            tenantMemberCount: newTenantUserIds?.length
              ? previousTenantMemberCount
              : Number.isInteger(previousTenantMemberCount)
              ? previousTenantMemberCount - 1
              : 0,
          },
        },
        tenantUsers: {
          ...state.tenantUsers,
          [projectId]: {
            ...state.tenantUsers[projectId],
            [orgId]: newTenantUserIds.length
              ? {
                  ...state.tenantUsers[projectId]?.[orgId],
                  userIds: newTenantUserIds,
                }
              : {},
          },
        },
      };

    case ASSIGN_BENEFICIARY_USER.SUCCESS:
      projectId = action.payload.projectId;
      userId = action.payload.userId;
      orgId = action.payload.organizationId;
      previousBeneficiaryMemberCount =
        state.usersStatistics[projectId]?.beneficiaryMemberCount;
      previousBeneficiaryUsers = state.beneficiaryUsers[projectId][orgId];

      return {
        ...state,
        usersStatistics: {
          ...state.usersStatistics,
          [projectId]: {
            ...state.usersStatistics[projectId],
            beneficiaryMemberCount: Number.isInteger(
              previousBeneficiaryMemberCount
            )
              ? previousBeneficiaryMemberCount + 1
              : 1,
          },
        },
        beneficiaryUsers: {
          ...state.beneficiaryUsers,
          [projectId]: {
            [orgId]: Array.isArray(previousBeneficiaryUsers)
              ? [...previousBeneficiaryUsers, userId]
              : [userId],
          },
        },
      };

    case DEASSIGN_BENEFICIARY_USER.SUCCESS:
      projectId = action.payload.projectId;
      userId = action.payload.userId;
      orgId = action.payload.organizationId;
      previousBeneficiaryMemberCount =
        state.usersStatistics[projectId]?.beneficiaryMemberCount;
      previousBeneficiaryUsers = state.beneficiaryUsers[projectId][orgId];
      const newBeneficiaryUsers = Array.isArray(previousBeneficiaryUsers)
        ? previousBeneficiaryUsers.filter(
            (beneficiaryUserId) => beneficiaryUserId !== userId
          )
        : [];

      return {
        ...state,
        usersStatistics: {
          ...state.usersStatistics,
          [projectId]: {
            ...state.usersStatistics[projectId],
            beneficiaryMemberCount: Number.isInteger(
              previousBeneficiaryMemberCount
            )
              ? previousBeneficiaryMemberCount - 1
              : 0,
          },
        },
        beneficiaryUsers: {
          ...state.beneficiaryUsers,
          [projectId]: {
            ...(newBeneficiaryUsers.length
              ? { [orgId]: newBeneficiaryUsers }
              : {}),
          },
        },
      };

    case ADD_TENANT_LOCATION.SUCCESS:
      tenantLocation = action.payload.location;
      projectId = action.payload.projectId;
      orgId = action.payload.organizationId;
      previousLocations = state.tenantUsers[projectId]?.[orgId]?.locations;
      updatedLocations = Array.isArray(previousLocations)
        ? [...previousLocations, tenantLocation]
        : [tenantLocation];

      return {
        ...state,
        tenantUsers: {
          ...state.tenantUsers,
          [projectId]: {
            ...state.tenantUsers[projectId],
            [orgId]: {
              ...state.tenantUsers[projectId]?.[orgId],
              locations: updatedLocations.sort(handleSortLocations),
            },
          },
        },
      };

    case REMOVE_TENANT_LOCATION.SUCCESS:
      tenantLocation = action.payload.location;
      projectId = action.payload.projectId;
      orgId = action.payload.organizationId;
      previousLocations = state.tenantUsers[projectId]?.[orgId]?.locations;

      return {
        ...state,
        tenantUsers: {
          ...state.tenantUsers,
          [projectId]: {
            ...state.tenantUsers[projectId],
            [orgId]: {
              ...state.tenantUsers[projectId]?.[orgId],
              locations: Array.isArray(previousLocations)
                ? previousLocations.filter((l) => l.id !== tenantLocation.id)
                : [],
            },
          },
        },
      };

    case GET_BUILDINGS.REQUEST:
      return {
        ...state,
        buildings: {
          ...state.buildings,
          getIsLoading: true,
        },
      };

    case GET_BUILDINGS.ERROR:
      return {
        ...state,
        buildings: {
          ...state.buildings,
          getIsLoading: false,
        },
      };

    case GET_BUILDINGS.SUCCESS:
      const buildings = action.payload.resources;
      projectId = action.payload.projectId;

      return {
        ...state,
        buildings: {
          ...state.buildings,
          getIsLoading: false,
          [projectId]: {
            ...state.buildings[projectId],
            activeBuildingId:
              state.buildings[projectId]?.activeBuildingId || buildings[0]?.id,
            buildingDictionary: buildings.reduce((acc, building) => {
              acc[building.id] = building;
              return acc;
            }, {}),
          },
        },
      };

    case CHANGE_ACTIVE_BUILDING_ID:
      const activeBuildingId = action.payload.newActiveBuildingId;
      projectId = action.payload.projectId;

      return {
        ...state,
        buildings: {
          ...state.buildings,
          [projectId]: {
            ...state.buildings[projectId],
            activeBuildingId,
          },
        },
      };

    case MOVE_BUILDING_LEVEL.SUCCESS:
      projectId = action.payload.projectId;
      buildingId = action.payload.buildingId;
      const currentProjectBuildingDictionary =
        state.buildings[projectId].buildingDictionary;

      return {
        ...state,
        buildings: {
          ...state.buildings,
          [projectId]: {
            ...state.buildings[projectId],
            buildingDictionary: {
              ...currentProjectBuildingDictionary,
              [buildingId]: {
                ...currentProjectBuildingDictionary[buildingId],
                levels: currentProjectBuildingDictionary[buildingId].levels.map(
                  (level) => ({
                    ...level,
                    positionIndex: action.payload.levelIdPositionMap[level.id],
                  })
                ),
              },
            },
          },
        },
      };

    case MOVE_BUILDING_LEVEL.ERROR:
      projectId = action.payload.projectId;
      buildingId = action.payload.buildingId;

      return {
        ...state,
        buildings: {
          ...state.buildings,
          [projectId]: {
            ...state.buildings[projectId],
            buildingDictionary: {
              ...state.buildings[projectId].buildingDictionary,
            },
          },
        },
      };

    case CREATE_BUILDING.SUCCESS:
      projectId = action.payload.projectId;
      buildingData = action.payload.building;
      buildingId = buildingData.id;

      return {
        ...state,
        buildings: {
          ...state.buildings,
          [projectId]: {
            ...state.buildings[projectId],
            activeBuildingId: !Object.keys(
              state.buildings[projectId].buildingDictionary
            ).length
              ? buildingId
              : state.buildings[projectId].activeBuildingId,
            buildingDictionary: {
              ...state.buildings[projectId].buildingDictionary,
              [buildingId]: {
                ...buildingData,
              },
            },
          },
        },
      };

    case DELETE_BUILDING.SUCCESS:
      projectId = +action.payload.projectId;
      buildingId = +action.payload.buildingId;

      const buildingToDeleteLevelIds = state.buildings[
        projectId
      ].buildingDictionary[buildingId].levels.map((level) => level.id);

      updatedBuildingDictionary = Object.keys(
        state.buildings[projectId].buildingDictionary
      ).reduce(
        (acc, bId) => {
          if (+bId === buildingId) {
            delete acc[bId];
            return acc;
          }

          acc[bId] = {
            ...acc[bId],
            levels: acc[bId].levels.map((level) => {
              if (buildingToDeleteLevelIds.includes(level.id)) {
                // eslint-disable-next-line no-param-reassign
                level.buildings = level.buildings.filter(
                  (building) => building.id !== buildingId
                );
              }

              return level;
            }),
          };
          return acc;
        },
        {
          ...state.buildings[projectId].buildingDictionary,
        }
      );

      const newActiveBuildingId = +Object.keys(updatedBuildingDictionary)[0];

      return {
        ...state,
        buildings: {
          ...state.buildings,
          [projectId]: {
            ...state.buildings[projectId],
            activeBuildingId: newActiveBuildingId,
            buildingDictionary: updatedBuildingDictionary,
          },
        },
      };

    case UPDATE_BUILDING.SUCCESS:
      projectId = action.payload.projectId;
      buildingData = action.payload.building;
      buildingId = buildingData.id;

      return {
        ...state,
        buildings: {
          ...state.buildings,
          [projectId]: {
            ...state.buildings[projectId],
            buildingDictionary: {
              ...state.buildings[projectId].buildingDictionary,
              [buildingId]: {
                ...state.buildings[projectId].buildingDictionary[buildingId],
                ...buildingData,
              },
            },
          },
        },
      };

    case DELETE_BUILDING_LEVEL.SUCCESS:
      projectId = +action.payload.projectId;
      buildingId = +action.payload.buildingId;
      levelId = +action.payload.levelId;

      updatedBuildingDictionary = Object.keys(
        state.buildings[projectId].buildingDictionary
      ).reduce(
        (acc, bId) => {
          acc[bId] = {
            ...acc[bId],
            levels: acc[bId].levels.filter((level) => level.id !== levelId),
          };
          return acc;
        },
        {
          ...state.buildings[projectId].buildingDictionary,
        }
      );

      return {
        ...state,
        buildings: {
          ...state.buildings,
          [projectId]: {
            ...state.buildings[projectId],
            buildingDictionary: updatedBuildingDictionary,
          },
        },
      };

    case DUPLICATE_BUILDING_LEVEL.SUCCESS:
      projectId = action.payload.projectId;
      buildingId = action.payload.buildingId;

      return {
        ...state,
        buildings: {
          ...state.buildings,
          [projectId]: {
            ...state.buildings[projectId],
            buildingDictionary: {
              ...state.buildings[projectId].buildingDictionary,
              [buildingId]: {
                ...state.buildings[projectId].buildingDictionary[buildingId],
                levels: [
                  ...state.buildings[projectId].buildingDictionary[buildingId]
                    .levels,
                  action.payload.duplicatedLevel,
                ],
              },
            },
          },
        },
      };

    case CREATE_BUILDING_LEVEL.SUCCESS:
      projectId = action.payload.projectId;
      buildingId = action.payload.buildingId;

      return {
        ...state,
        buildings: {
          ...state.buildings,
          [projectId]: {
            ...state.buildings[projectId],
            buildingDictionary: {
              ...state.buildings[projectId].buildingDictionary,
              [buildingId]: {
                ...state.buildings[projectId].buildingDictionary[buildingId],
                levels: [
                  ...state.buildings[projectId].buildingDictionary[buildingId]
                    .levels,
                  action.payload.level,
                ],
              },
            },
          },
        },
      };

    case UPDATE_BUILDING_LEVEL.SUCCESS:
      projectId = action.payload.projectId;
      buildingId = action.payload.buildingId;
      const updatedLevel = action.payload.level;

      updatedBuildingDictionary = Object.keys(
        state.buildings[projectId].buildingDictionary
      ).reduce(
        (acc, bId) => {
          acc[bId] = {
            ...acc[bId],
            levels: acc[bId].levels.map((level) =>
              level.id === updatedLevel.id ? updatedLevel : level
            ),
          };
          return acc;
        },
        {
          ...state.buildings[projectId].buildingDictionary,
        }
      );

      return {
        ...state,
        buildings: {
          ...state.buildings,
          [projectId]: {
            ...state.buildings[projectId],
            buildingDictionary: updatedBuildingDictionary,
          },
        },
      };

    case UNLINK_BUILDING_LEVEL.SUCCESS:
      projectId = +action.payload.projectId;
      buildingId = +action.payload.buildingId;
      levelId = +action.payload.levelId;
      const { unlinkedBuildingId } = action.payload;

      return {
        ...state,
        buildings: {
          ...state.buildings,
          [projectId]: {
            ...state.buildings[projectId],
            buildingDictionary: {
              ...state.buildings[projectId].buildingDictionary,
              [buildingId]: {
                ...state.buildings[projectId].buildingDictionary[buildingId],
                levels: state.buildings[projectId].buildingDictionary[
                  buildingId
                ].levels.map((level) =>
                  level.id === levelId
                    ? {
                        ...level,
                        buildings: level.buildings.filter(
                          (b) => b.id !== unlinkedBuildingId
                        ),
                      }
                    : level
                ),
              },
              [unlinkedBuildingId]: {
                ...state.buildings[projectId].buildingDictionary[
                  unlinkedBuildingId
                ],
                levels: state.buildings[projectId].buildingDictionary[
                  unlinkedBuildingId
                ].levels.filter((level) => level.id !== levelId),
              },
            },
          },
        },
      };

    case LINK_BUILDING_LEVEL.SUCCESS:
      projectId = +action.payload.projectId;
      buildingId = +action.payload.buildingId;
      levelId = +action.payload.levelId;
      const { linkedBuildingId } = action.payload;

      const linkedBuilding =
        state.buildings[projectId].buildingDictionary[linkedBuildingId];
      const simpleBuildingData = {
        id: linkedBuilding.id,
        name: linkedBuilding.name,
        positionIndex: linkedBuilding.positionIndex,
      };

      const linkedLevel = state.buildings[projectId].buildingDictionary[
        buildingId
      ].levels.find((level) => level.id === levelId);
      linkedLevel.buildings = [...linkedLevel.buildings, simpleBuildingData];

      return {
        ...state,
        buildings: {
          ...state.buildings,
          [projectId]: {
            ...state.buildings[projectId],
            buildingDictionary: {
              ...state.buildings[projectId].buildingDictionary,
              [buildingId]: {
                ...state.buildings[projectId].buildingDictionary[buildingId],
                levels: state.buildings[projectId].buildingDictionary[
                  buildingId
                ].levels.map((level) =>
                  level.id === levelId ? linkedLevel : level
                ),
              },
              [linkedBuildingId]: {
                ...state.buildings[projectId].buildingDictionary[
                  linkedBuildingId
                ],
                levels: [
                  ...state.buildings[projectId].buildingDictionary[
                    linkedBuildingId
                  ].levels,
                  linkedLevel,
                ],
              },
            },
          },
        },
      };

    default:
      return state;
  }
}
